<template>
    <div>
        <!--顶部工具条-->
        <el-col :span="24">
            <el-form :inline="true">
                <el-form-item label="审批记录名称:">
                    <el-input placeholder="请输入关键字" v-model="filters.recordName"></el-input>
                </el-form-item>
                <el-form-item label="审批流程类型:">
                    <el-select v-model="filters.processType">
                        <el-option
                        v-for="item in optionsData2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                </el-form-item>
            </el-form>
        </el-col>
        <el-table
        :data="tableData"
        border
        v-loading="listLoading"
        highlight-current-row
        @current-change="handlerowChange"
        style="width: 95%">
            <el-table-column prop="ApprovalProcessName" label="审批流程名称" width=""></el-table-column>
            <el-table-column prop="RecordName" label="审批记录名称" width=""></el-table-column>
            <el-table-column prop="ProcessType" label="审批流程类型" align="center" width="180">
                <template slot-scope="scope">
                    <el-tag
                    :type="'success'"
                    disable-transitions v-if="scope.row.ProcessType== 0">{{"转为字典试题"}}
                    </el-tag>
                    <el-tag
                    :type="'success'"
                    disable-transitions v-if="scope.row.ProcessType== 1">{{"字典试题变更"}}
                    </el-tag>
                    <el-tag
                    :type="'success'"
                    disable-transitions v-if="scope.row.ProcessType== 2">{{"发布考试项目"}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="ApprovalStatus" label="审核状态" align="center" width="180">
                <template slot-scope="scope">
                    <el-tag
                    :type="''"
                    disable-transitions v-if="scope.row.ApprovalStatus== 0">{{"未审批"}}
                    </el-tag>
                    <el-tag
                    :type="''"
                    disable-transitions v-if="scope.row.ApprovalStatus== 1">{{"审核中"}}
                    </el-tag>
                    <el-tag
                    :type="''"
                    disable-transitions v-if="scope.row.ApprovalStatus== 2">{{"审核通过"}}
                    </el-tag>
                    <el-tag
                    :type="''"
                    disable-transitions v-if="scope.row.ApprovalStatus== 3">{{"审核失败"}}
                    </el-tag>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="Remarks" label="备注"></el-table-column> -->
            <el-table-column prop="CreateTime" label="创建时间" align="center" width="180" :formatter="formatCreateTime"   :sortable='true'></el-table-column>
            <el-table-column
            fixed="right"
            label="操作"
            width="200"
            align="center">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="primary" plain size="mini" icon="el-icon-notebook-1">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <el-dialog
        :title="isViews?'审批':'查看'"
        :visible.sync="relatedQuestion"
        v-model="relatedQuestion"
        :close-on-click-modal="false"
        >   
            <convert-diceq v-if="currentRow.ProcessType === 0 || currentRow.ProcessType === 1" :dataObj="dataObj"></convert-diceq>
            <release-project v-if="currentRow.ProcessType === 2" :dataObj="dataObj"></release-project>
            <el-divider></el-divider>
            <approval-record :dataObj="dataObj"></approval-record>
            <el-form v-if="isViews" :model="flowForm" label-width="100px" label-position="center" ref="flowFormRef" :rules="flowFormRules" style="margin-top:15px;">
                <el-form-item label="审批意见:" prop="flowIdea">
                    <el-input v-model="flowForm.flowIdea"></el-input>
                </el-form-item> 
            </el-form>
            <div slot="footer" class="dialog-footer" v-if="isViews">
                <el-button @click.native="relatedQuestion = false">取消</el-button>
                <el-button type="info" @click.native="noAgreeFlow" :loading="addLoading">拒绝</el-button>
                <el-button type="primary" @click.native="agreeFlow" :loading="addLoading">同意</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getPendingIssuesListPage,getExaminationQuestionsApprovalRecordByID,approve,getPublishExamProjectInfoByID} from '../../api/api';
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import convertDiceq from "./ConvertDicEQ";
import approvalRecord from "./approvalRecord";
import releaseProject from "./Public";
import util from "../../../util/date";
import Qs from 'qs'
export default {
    components: { Toolbar,convertDiceq,approvalRecord,releaseProject},
    data() {
        return{
            //增删改查按钮
            buttonList: [],
            listLoading:false,
            relatedQuestion:false,
            currentRow: '',
            filters: {
                recordName: '',
                processType: '',
            },
            optionsData2: [{
                value: '33',
                label: '所有试题'
            },{
                value: '0',
                label: '转为字典试题'
            },{
                value: '1',
                label: '字典试题变更'
            },{
                value: '2',
                label: '发布考试项目'
            }],
            tableData: [],
            pages: {  //关于分页的obj
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            dataObj: '',
            addLoading: false,
            tableData1: [],
            flowForm: {
               flowIdea: '', 
            },
            flowFormRules:{
                flowIdea:[{required:true,message:'请输入审批意见',trigger:'blur'}],
            },
            isViews: true
        }
    },
    methods: {
        // 增，删，改，查
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 查询
        getPendingIssuesListPage() {
            this.pages.pageIndex = 1
            this.getTableList();
        },
        // 表格当前行改变事件
        handlerowChange(val){
            this.currentRow = val
        },
        // 操作查看/
        handleClick(val) {
            this.currentRow = val
            this.relatedQuestion = true
            this.isViews = false
            var params = {
                approvalProcessRecordID: val.ID
            }
            if(val.ProcessType === 0 || val.ProcessType === 1){
                this.getListDeatilById(params);
            }else if(val.ProcessType === 2){
                this.getListPublishExamById(params)
            }
        },
        // 根据审批记录ID获取发布考试项目详情
        getListPublishExamById(params){
            var _this = this
            getPublishExamProjectInfoByID(params).then(res => {
                var resData = res.data
                if(resData.Success){
                    this.dataObj = resData.Response
                    this.tableData1 = resData.Response.ApprovalSteps
                }else{
                    _this.$message({
                        message: resData.Message,
                        type: "error"
                    })
                }
            })
        },
        // 审批
        handleApprove() {
            if(this.currentRow){
                this.flowForm.flowIdea = ''
                this.relatedQuestion = true
                this.isViews = true
                var params = {
                    approvalProcessRecordID: this.currentRow.ID
                }
                if(this.currentRow.ProcessType === 0 || this.currentRow.ProcessType === 1){
                    this.getListDeatilById(params);
                }else if(this.currentRow.ProcessType === 2){
                    this.getListPublishExamById(params)
                }
            }else{
                this.$message({
                    message: "请选择需要审批项！",
                    type: "warning"
                });
            }
        },
        // 同意审批
        agreeFlow() {
            this.$refs['flowFormRef'].validate((valid) => {
                if(valid){
                    var params = {
                        ApprovalProcessRecordID: this.currentRow.ID,
                        ApprovalComments: this.flowForm.flowIdea,
                        ApproveResult: true
                    }
                    this.submitFlow(params);
                }
            })
        },
        // 拒绝
        noAgreeFlow() {
            this.$refs['flowFormRef'].validate((valid) => {
                if(valid){
                    var params = {
                        ApprovalProcessRecordID: this.currentRow.ID,
                        ApprovalComments: this.flowForm.flowIdea,
                        ApproveResult: false
                    }
                    this.submitFlow(params);
                }
            })
        },
        submitFlow(params) {
            approve(params).then(res => {
                var resData = res.data
                if(resData.Success){
                    this.$message({
                        message: resData.Message,
                        type: "success"
                    });
                    this.relatedQuestion = false;
                    this.getTableList();
                }else{
                    this.$message({
                        message: resData.Message,
                        type: "error"
                    });
                }
            })
        },
        getTableList() {
            var _this = this
            var params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            if(this.filters.recordName){
                params.recordName = this.filters.recordName
            }
            if(this.filters.processType && this.filters.processType != 33){
                params.processType = this.filters.processType
            }
            this.listLoading = true
            getPendingIssuesListPage(params).then(res => {
                var resData = res.data
                if(resData.Success){
                    this.listLoading = false
                    this.tableData = resData.Response.Data
                    this.pages.dataCount = resData.Response.DataCount
                    this.pages.pageIndex = resData.Response.PageIndex
                    if(resData.Response.PageSize > 0){
                        this.pages.pageSize = resData.Response.PageSize
                    }
                }else{
                    _this.$message({
                        message: resData.Message,
                        type: "error"
                    });
                    setTimeout(() => {
                        _this.listLoading = false
                    },3000)
                }
            });
        },
        // 根据ID获取详情
        getListDeatilById(params) {
            var _this = this
            getExaminationQuestionsApprovalRecordByID(params).then(res => {
                var resData = res.data
                if(resData.Success){
                    this.dataObj = resData.Response
                    this.tableData1 = resData.Response.ApprovalSteps
                }else{
                    _this.$message({
                        message: resData.Message,
                        type: "error"
                    });
                }
            });
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getTableList();
        },
        handleSizeChange(val){
            this.pages.pageSize = val;
            this.getTableList();
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        }

    },
    mounted() {
        this.getTableList();
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    
</style>